import {Dialog, Link} from "@mui/material";

export type HelpDialogProps = {
    open: boolean,
    onClose: () => void;
}

export default function HelpDialog({open, onClose}: HelpDialogProps) {
    return (<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true}>
        <div style={{padding: "2em", margin: 0}}>
            <h3 style={{marginTop: 0}}>Welcome to Expedient - AI CTRL Chat (ALPHA)</h3>

            This is an Alpha version of a new frontend for our AI CTRL Chat product. You may encounter bugs and errors.
            The new frontend application saves your conversations, and allows you to switch between them.
            It also allows you to change the system prompt, so you can customize the AI's basic behavior to your liking.
            Please let us know if you encounter any issues not listed below so that we can resolve them as soon as
            possible.

            Your use of this system falls under our employee handbook and AI Policy, please use it responsibly.

            <ul>
                <li>
                    <p><strong>AI Policy</strong> <Link
                        href="https://expedient365.sharepoint.com/:b:/r/sites/EmployeeEngagement/Shared%20Documents/Employee%20Handbook/CBB%20Security%20Policy.pdf?csf=1&amp;web=1&amp;e=3J4rmY"
                        target="_blank">Expedient Employee AI Policy</Link></p>
                </li>
                <li>
                    <p><strong>Employee AI Success Kit:</strong> <Link
                        href="https://engage.expedient.com/wl/758dd3" target="_blank">Expedient
                        Employee AI Success Kit</Link> Various documents, demos, and walkthroughs to help you use the AI
                        system effectively.</p>
                </li>
                <li>
                    <p><strong>Help</strong> If you have questions about the system, please contact Brad Reynolds by
                        email at <Link
                            href="mailto:bradley.reynolds@expedient.com"
                            target="_blank">bradley.reynolds@expedient.com</Link> or teams chat <Link
                            href="https://teams.microsoft.com/l/chat/0/0?users=bradley.reynolds@expedient.com&amp;topicName=AI%20Chat%20Assistance&amp;message=AI%20Chat%20Assistance%20Question"
                            target="_blank">Bradley Reynolds</Link></p>
                </li>
                <li>
                    <p><strong>Feature Bounty Program</strong> If you have a suggestion for improvement that gets
                        implemented in a future release of AI CTRL Chat, you will receive a <strong>$50 bounty</strong>,
                        please submit to Brad</p>
                </li>
                <li>
                    <p><strong>Bug Bounty Program</strong> If you find a bug that we fix in a future release of AI CTRL
                        Chat, you will receive a <strong>$50 bounty</strong>, please submit to Brad</p>
                </li>
            </ul>
        </div>

    </Dialog>)
}