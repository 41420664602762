import IModel from "../types/enums/IModel";

export default function sortLargeLanguageModels(
  llmA: IModel,
  llmB: IModel,
): -1 | 1 | 0 {
  if (llmA.default && !llmB.default) return -1;
  if (llmB.default && !llmA.default) return 1;
  const nameA = llmA.display_name.toLowerCase();
  const nameB = llmB.display_name.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}
