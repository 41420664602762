import { useState } from "react";
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  TextField, 
  DialogActions, 
  Button
} from "@mui/material"

export type FeedbackModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (details: string) => void;
}

export default function FeedbackModal({ 
    isVisible, 
    onClose, 
    onSubmit 
}: FeedbackModalProps) {
  const [details, setDetails] = useState('');

  if (!isVisible) {
    return (
      <div></div>
    );
  }

  const handleCancel = () => {
    setDetails('');
    onClose();
  }

  const handleSubmit = () => {
    onSubmit(details);
    setDetails('');
    onClose();
  };

  return (
    <Dialog 
      open={isVisible} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Provide additional feedback</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="(Optional) Add specific details here"
          type="text"
          fullWidth
          variant="outlined"
          rows={4}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
