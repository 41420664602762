import { ChangeEvent, useState } from "react";
import { Box, Button, Card, Dialog, TextField } from "@mui/material";
import getDefaults from "../utilities/defaults";

export type GlobalSettingsProps = {
  open: boolean;
  currentDefaultSystemPrompt: string;
  onSave: (newDefaultSystemPrompt: string) => void;
  onClose: () => void;
};

export default function GlobalSettings({
  open,
  currentDefaultSystemPrompt,
  onSave,
  onClose,
}: GlobalSettingsProps) {
  const [defaultSystemPrompt, setDefaultSystemPrompt] = useState<string>(
    currentDefaultSystemPrompt,
  );

  const handleClose = () => {
    setDefaultSystemPrompt(currentDefaultSystemPrompt);
    onClose();
  };

  const handleSave = () => {
    onSave(defaultSystemPrompt);
    onClose();
  };

  const handleDefaultSystemPromptChange = (
    event: ChangeEvent<{ value: unknown }>,
  ) => {
    setDefaultSystemPrompt(event.target.value as string);
  };

  const handleResetPrompt = () => {
    setDefaultSystemPrompt(getDefaults().defaultSystemPrompt);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <Card sx={{ padding: "1em", width: "100%" }}>
        <TextField
          sx={{ width: "100%" }}
          multiline={true}
          maxRows={10}
          value={defaultSystemPrompt}
          label={"Default System Prompt"}
          onChange={handleDefaultSystemPromptChange}
        />
        <Button onClick={handleResetPrompt}>Reset</Button>
        <Box display="flex" sx={{ mt: "1em" }}>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Box>
      </Card>
    </Dialog>
  );
}
