import cleanGatewayResponse from "./cleanGatewayResponse";

export default async function readMessageStream(
  stream: ReadableStream,
  onNewChunk: (token: string) => void = () => {}, // callback function gets chunks as they arrive
) {
  const reader = stream.getReader();
  const chunks: string[] = [];

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    if (value) {
      const chunk = cleanGatewayResponse(value);
      chunks.push(chunk);
      onNewChunk(chunk);
    }
  }

  return chunks.join("");
}
