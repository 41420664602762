import { Box, Button, CssBaseline, Dialog, Typography } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { DarkModeEnabledContext, OidcConfigurationNameContext } from "./index";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const darkMode = useContext(DarkModeEnabledContext);
  const navigate = useNavigate();
  const { setOidcConfigurationName } = useContext(OidcConfigurationNameContext);
  return (
    <Fragment>
      <CssBaseline />
      <Dialog open={true} maxWidth="md" fullWidth={true}>
        <Box
          sx={{ minHeight: "50vh" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: "1em" }}
          >
            <img
              src={darkMode ? "/logo-dark.png" : "/logo-light.png"}
              style={{ maxWidth: "60%" }}
            />
            <Typography variant="h5">Authenticate to Continue</Typography>
            <Button
              variant="contained"
              onClick={() => {
                setOidcConfigurationName("azureOidcConfig");
                navigate("/");
              }}
            >
              Continue with Azure AD
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOidcConfigurationName("oneloginOidcConfig");
                navigate("/");
              }}
            >
              Continue with OneLogin
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Fragment>
  );
}
