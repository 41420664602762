import ILargeLanguageModel from "../types/interfaces/ILargeLanguageModel";

export default function getDefaultModelFromList(
  llms: ILargeLanguageModel[],
): ILargeLanguageModel {
  if (llms.length === 0) {
    throw new Error(
      "There are no available LLMs. This function should never be called from a context where LLMs are not yet loaded.",
    );
  }
  return llms.find((llm) => llm.default) ?? llms[0];
}
