import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import IConversationSettings from "../types/interfaces/IConversationSettings";
import IModel from "../types/enums/IModel";
import { AvailableModelsContext } from "../index";
import getDefaultModelFromList from "../utilities/getDefaultModelFromList";
import { UserContext } from "./ActiveConversationWindow";
import sortLargeLanguageModels from "../utilities/sortLargeLanguageModels";

export type ConversationSettingsProps = {
  open: boolean;
  onClose: () => void;
  onSave: (newSettings: IConversationSettings) => void;
  currentModel: IModel;
  currentSystemPrompt: string;
  currentPii: boolean;
  currentRag: boolean;
  defaultSystemPrompt: string;
};

export default function ConversationSettings({
  open,
  onClose,
  onSave,
  currentModel,
  currentSystemPrompt,
  currentPii,
  currentRag,
  defaultSystemPrompt,
}: ConversationSettingsProps) {
  const [systemPrompt, setSystemPrompt] = useState<string>(currentSystemPrompt);
  const [model, setModel] = useState<IModel>(currentModel);
  const [rag, setRag] = useState<boolean>(currentRag);
  const [pii, setPii] = useState<boolean>(currentPii);
  const llms = useContext(AvailableModelsContext);
  const { userName, roles } = useContext(UserContext);

  const handleClose = () => {
    setPii(currentPii);
    setRag(currentRag);
    setModel(currentModel);
    setSystemPrompt(currentSystemPrompt);
    onClose();
  };

  useEffect(() => {
    setModel(currentModel);
    setRag(currentRag);
    setPii(currentPii);
    setSystemPrompt(currentSystemPrompt);
  }, [currentSystemPrompt, currentPii, currentRag, currentModel]);

  const handleSave = () => {
    onSave({
      systemPrompt: systemPrompt,
      model: model,
      pii: pii,
      rag: rag,
    });
    onClose();
  };

  const handleSystemPromptChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSystemPrompt(event.target.value as string);
  };

  const handleModelChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setModel(
      llms.find((llm) => llm.internal_name === event.target.value) ??
        getDefaultModelFromList(llms),
    );
  };

  const handlePiiChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPii((prev) => !prev);
  };

  const handleRagChange = (event: ChangeEvent<{ checked: boolean }>) => {
    setRag((prev) => !prev);
  };

  const handleResetSystemPrompt = () => {
    setSystemPrompt(defaultSystemPrompt);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <Card sx={{ padding: "1em", width: "100%" }}>
        <Box>
          <FormGroup
            sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
          >
            <TextField
              multiline={true}
              maxRows={10}
              value={systemPrompt}
              label={"System Prompt"}
              onChange={handleSystemPromptChange}
            />
            <Button onClick={handleResetSystemPrompt}>
              Reset System Prompt to Global Default
            </Button>
            <FormControl>
              <InputLabel id="modelSelectLabel">Model</InputLabel>
              <Select
                labelId="modelSelectLabel"
                // @ts-ignore
                value={model.internal_name}
                label="Model"
                onChange={handleModelChange}
              >
                {
                  /*Object.values(IModel).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))*/
                  llms
                    .filter((m) =>
                      m.allowed_groups.some(
                        (g) => roles.includes(g) || g === "*",
                      ),
                    )
                    .sort(sortLargeLanguageModels)
                    .map((m) => (
                      <MenuItem key={m.internal_name} value={m.internal_name}>
                        {m.default ? (
                          <b>{m.display_name + " (Default)"}</b>
                        ) : (
                          m.display_name
                        )}
                      </MenuItem>
                    ))
                }
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleRagChange}
                  name={"RAG"}
                  checked={rag}
                />
              }
              label={"Use stored documents to inform responses (RAG)"}
            />
          </FormGroup>
          <Box display="flex" sx={{ mt: "1em" }}>
            <Button onClick={handleClose} color="error">
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </Box>
        </Box>
      </Card>
    </Dialog>
  );
}
