import hast from "hast";

export default function getNestedClassNamesFromElementChildren(
  carry: (string | number | boolean)[],
  current: hast.Element,
) {
  if (current.properties?.className == undefined) {
    return carry;
  }
  return [
    ...carry,
    ...(Array.isArray(current.properties.className)
      ? current.properties.className
      : [current.properties.className]),
  ];
}
