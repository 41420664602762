import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApmRoutes } from "@elastic/apm-rum-react";
import {
  OidcConfiguration,
  OidcProvider,
  OidcSecure,
} from "@axa-fr/react-oidc";
import Chat from "./Chat";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import LoginPage from "./LoginPage";
import { init as initApm } from "@elastic/apm-rum";
import useLocalStorage from "./hooks/useLocalStorage";
import "./index.css";
import useSessionStorage from "./hooks/useSessionStorage";
import {
  getAllModels,
  getOidcConfigurations,
} from "./utilities/sendGatewayRequest";
import ILargeLanguageModel from "./types/interfaces/ILargeLanguageModel";

const apm = initApm({
  serviceName: process.env.REACT_APP_APM_SERVICE_NAME as string,
  serverUrl: process.env.REACT_APP_APM_SERVER_URL as string,
  serviceVersion: process.env.REACT_APP_APM_SERVICE_VERSION as string,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const Spinner = () => {
  const lightMode = !window.matchMedia("(prefers-color-scheme: dark)").matches;
  return (
    <html style={{ backgroundColor: lightMode ? "white" : "black" }}>
      <body>
        <div>
          Loading OIDC configurations and LLMs...
          <CssBaseline />
        </div>
      </body>
    </html>
  );
};

export const DarkModeEnabledContext = createContext<boolean>(true);
export const OidcConfigurationNameContext = createContext<{
  oidcConfigurationName: string;
  clearOidcConfigurationName: () => void;
  setOidcConfigurationName: (newName: string) => void;
}>({
  oidcConfigurationName: "default",
  clearOidcConfigurationName: () => {},
  setOidcConfigurationName: (_) => {},
});
export const AvailableModelsContext = createContext<Array<ILargeLanguageModel>>(
  [],
);

const AppContainer = () => {
  const [oidcConfigurationName, setOidcConfigurationName] =
    useLocalStorage<string>("configurationName", "default");
  const clearAuthProvider = () => {
    setOidcConfigurationName("default");
  };
  const [oidcConfigurations, setOidcConfigurations] = useSessionStorage<{
    oneloginOidcConfig: OidcConfiguration;
    azureOidcConfig: OidcConfiguration;
  } | null>("oidcConfigs", null);
  const [darkMode, setDarkMode] = useState<boolean>(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches,
  );
  const [availableModels, setAvailableModels] = useState<
    Array<ILargeLanguageModel>
  >([]);
  const darkTheme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: process.env.REACT_APP_PRIMARY_COLOR ?? "#CC0000",
      },
      secondary: {
        main: "#E0C2FF",
        light: "#F5EBFF",
        contrastText: "#47008F",
      },
    },
  });

  useEffect(() => {
    (async () => {
      const oidcConfigResponse = await getOidcConfigurations();
      const onelogin = oidcConfigResponse.find((x) =>
        x.name.includes("onelogin"),
      )!;
      const azure = oidcConfigResponse.find((x) => x.name.includes("azure"))!;
      const newConfigs: {
        oneloginOidcConfig: OidcConfiguration;
        azureOidcConfig: OidcConfiguration;
      } = {
        oneloginOidcConfig: {
          client_id: onelogin.client_id,
          redirect_uri: window.location.origin + onelogin.redirect_uri,
          silent_redirect_uri:
            window.location.origin + onelogin.silent_redirect_uri,
          scope: onelogin.scope,
          authority: onelogin.authority,
          service_worker_only: false,
          demonstrating_proof_of_possession: false,
        },
        azureOidcConfig: {
          client_id: azure.client_id,
          redirect_uri: window.location.origin + azure.redirect_uri,
          silent_redirect_uri:
            window.location.origin + azure.silent_redirect_uri,
          scope: azure.scope,
          authority: azure.authority,
          service_worker_only: false,
          demonstrating_proof_of_possession: false,
        },
      };

      setOidcConfigurations((prev) => newConfigs);
    })();
  }, [setOidcConfigurations]);

  useEffect(() => {
    (async () => {
      const availableModelsResponse = await getAllModels();
      setAvailableModels(availableModelsResponse);
    })();
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((prev) => !prev);
  };

  useEffect(() => {
    if (!apm) {
      console.log("APM is not loaded.");
    }
  }, []);

  useEffect(() => {
    const r: HTMLElement | null = document.querySelector(":root");
    r?.style.setProperty(
      "--primary-color",
      process.env.REACT_APP_PRIMARY_COLOR ?? "#CC0000",
    );
  }, []);
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    document.title = "Expedient Chat";
  }, []);

  if (oidcConfigurations === null || availableModels.length === 0) {
    return <Spinner />;
  }
  if (oidcConfigurationName !== "default") {
    return (
      <div>
        <OidcConfigurationNameContext.Provider
          value={{
            oidcConfigurationName: oidcConfigurationName,
            clearOidcConfigurationName: clearAuthProvider,
            setOidcConfigurationName: setOidcConfigurationName,
          }}
        >
          <ThemeProvider theme={darkTheme}>
            <DarkModeEnabledContext.Provider value={darkMode}>
              <CssBaseline />
              <OidcProvider
                configuration={
                  // @ts-ignore
                  oidcConfigurations[oidcConfigurationName]
                }
                // @ts-ignore
                configurationName={oidcConfigurationName}
                loadingComponent={Spinner}
                authenticatingComponent={Spinner}
                callbackSuccessComponent={Spinner}
                sessionLostComponent={Spinner}
              >
                <AvailableModelsContext.Provider value={availableModels}>
                  <BrowserRouter>
                    <ApmRoutes>
                      <Route
                        path="/"
                        element={
                          <AdminSecureChat toggleDarkMode={toggleDarkMode} />
                        }
                      />
                      <Route path="/login" element={<LoginPage />} />
                    </ApmRoutes>
                  </BrowserRouter>
                </AvailableModelsContext.Provider>
              </OidcProvider>
            </DarkModeEnabledContext.Provider>
          </ThemeProvider>
        </OidcConfigurationNameContext.Provider>
      </div>
    );
  }
  return (
    <Fragment>
      <ThemeProvider theme={darkTheme}>
        <OidcConfigurationNameContext.Provider
          value={{
            oidcConfigurationName: oidcConfigurationName,
            clearOidcConfigurationName: clearAuthProvider,
            setOidcConfigurationName: setOidcConfigurationName,
          }}
        >
          <DarkModeEnabledContext.Provider value={darkMode}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
              </Routes>
            </BrowserRouter>
          </DarkModeEnabledContext.Provider>
        </OidcConfigurationNameContext.Provider>
      </ThemeProvider>
    </Fragment>
  );
};

const AdminSecureChat = ({
  toggleDarkMode,
}: {
  toggleDarkMode: () => void;
}) => {
  const { oidcConfigurationName } = useContext(OidcConfigurationNameContext);
  return (
    <OidcSecure configurationName={oidcConfigurationName}>
      <Chat toggleDarkMode={toggleDarkMode} />
    </OidcSecure>
  );
};

root.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
);
