import copy from "copy-to-clipboard";
import { Box, Card, IconButton } from "@mui/material";
import { ContentPaste } from "@mui/icons-material";
import hast from "hast";
import getNestedClassNamesFromElementChildren from "../utilities/getNestedClassNamesFromElementChildren";
import React from "react";
import reactNodeToString from "react-node-to-string";

export type CodeBlockProps = {
  node: hast.Element;
  children: React.ReactElement;
};

export default function CodeBlock({ node, children }: CodeBlockProps) {
  const elementChildren = node.children.filter(
    (child) => child.type === "element",
  ) as hast.Element[];
  let classNames = elementChildren.reduce(
    getNestedClassNamesFromElementChildren,
    [],
  );
  const languageClassNames = classNames
    .map((n) => n.toString())
    .filter((n) => n.startsWith("language-"))
    .map((n) => n.replace("language-", ""));
  const blockLanguage =
    languageClassNames.length === 1 ? languageClassNames[0] : null;
  const copyContent = () => {
    copy(reactNodeToString(children));
  };
  return (
    <>
      <Card
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: "960px",
          padding: "0.5em",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          {blockLanguage ? (
            <code>{blockLanguage.toLocaleUpperCase()}</code>
          ) : (
            <></>
          )}
          <IconButton onClick={copyContent}>
            <ContentPaste />
          </IconButton>
        </Box>
        <Box sx={{ overflowX: "auto", m: 0, padding: "1em", maxWidth: "100%" }}>
          <pre style={{ margin: 0, padding: 0 }}>{children}</pre>
        </Box>
      </Card>
    </>
  );
}
