export default function downloadText(name: string, content: string) {
  const link = document.createElement("a");
  const blob = new Blob([content], {
    type: "text/json",
  });
  link.download = name;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

  const evt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove();
}
