export default function formatDateOrDateString(d: Date | string, fancy: boolean = true) {
    let fancyOpts = {
        timeZone: 'America/New_York',
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }
    if (fancy) {
        const date = d instanceof Date ? d : new Date(d);
        return date.toLocaleString('en-us', {
            timeZone: 'America/New_York',
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    }
    return d instanceof Date ? d.toLocaleString('en-us') : new Date(d).toLocaleDateString('en-us');
}