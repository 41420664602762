/**
 * Cleans up the chunk of streamed response data
 * for now we're just going to throw out everything other than the text, but maybe in the future we will do something
 * with the rest of the data.
 * @param data
 */
export default function cleanGatewayResponse(
  data: Uint8Array | undefined,
): string {
  let decoded = new TextDecoder().decode(data);
  let text = "";
  // attempt to parse image data, if this fails it's not an image
  try {
    const image = JSON.parse(decoded);
    if (image?.data !== undefined && image.data[0] !== undefined) {
      return `![${image.data[0].revised_prompt}](${image.data[0].url})`;
    }
  } catch (error) {}
  for (let l of decoded.split("\n")) {
    try {
      l = l.trim();
      l = l.replace("data: ", "");
      if (l === "") continue;
      const parsed = JSON.parse(l);
      let content = parsed.choices[0].delta.content;
      text = text.concat(content);
    } catch (error) {
      console.error(
        `There was an error cleaning the streamed data: ${error}. Raw data: ${l}`,
      );
      return text;
    }
  }
  return text;
}
