import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { DarkModeEnabledContext, OidcConfigurationNameContext } from "../index";
import { useOidc } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router-dom";
import {
  DarkMode,
  Info,
  LightMode,
  Logout,
  Menu,
  MenuOpen,
  Settings,
} from "@mui/icons-material";

export type ToolBarProps = {
  toggleDarkMode: () => void;
  mobile: boolean;
  menuOpen: boolean;
  userName: string | undefined;
  roles: string[];
  onHelpOpened: () => void;
  onListToggle: () => void;
  onGlobalSettingsOpened: () => void;
};

export default function TopBar({
  toggleDarkMode,
  mobile,
  menuOpen,
  userName,
  roles,
  onHelpOpened,
  onListToggle,
  onGlobalSettingsOpened,
}: ToolBarProps) {
  const { oidcConfigurationName, clearOidcConfigurationName } = useContext(
    OidcConfigurationNameContext,
  );
  const darkMode = useContext(DarkModeEnabledContext);
  const oidc = useOidc(oidcConfigurationName);
  const navigate = useNavigate();
  const handleLogout = () => {
    oidc.logout(null, { "no_reload:oidc": "true" }).then(() => {
      clearOidcConfigurationName();
      sessionStorage.clear();
      navigate("/login");
    });
  };
  const handleHelp = () => {
    onHelpOpened();
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, maxWidth: "100vw" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "64px",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            width: mobile ? "100%" : "auto",
            justifyContent: mobile ? "space-between" : "start",
            gap: "2em",
          }}
        >
          {menuOpen ? (
            <Tooltip title={"Hide conversation list"}>
              <IconButton onClick={onListToggle}>
                <MenuOpen />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={"Show conversation list"}>
              <IconButton onClick={onListToggle}>
                <Menu />
              </IconButton>
            </Tooltip>
          )}
          <img
            alt={"logo"}
            src={darkMode ? "/logo-dark-bar.png" : "/logo-light-bar.png"}
            style={{
              objectFit: "contain",
              width: "auto",
              maxHeight: mobile ? "50%" : "75%",
            }}
          />
          {mobile ? (
            <Tooltip title={"Information"}>
              <IconButton onClick={handleHelp}>
                <Info />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Box>
        {mobile ? (
          <></>
        ) : (
          <Box
            sx={{ width: "max-content", display: "flex", alignItems: "center" }}
          >
            <Tooltip title={"Global Settings"}>
              <IconButton onClick={onGlobalSettingsOpened}>
                <Settings />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Information"}>
              <IconButton onClick={handleHelp}>
                <Info />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Toggle Dark Mode"}>
              <IconButton onClick={toggleDarkMode}>
                {darkMode ? <LightMode /> : <DarkMode />}
              </IconButton>
            </Tooltip>
            <Tooltip title={"Available Roles: " + roles ?? [""].join(", ")}>
              <Typography>
                <b>{userName}</b>
              </Typography>
            </Tooltip>
            <Tooltip title={"Log out of " + userName + "'s account"}>
              <IconButton onClick={handleLogout}>
                <Logout />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
