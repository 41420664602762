import {useState, useEffect} from "react";

function getLocalStorageValue<T>(key: string, defaultValue: T): T {
    const val = localStorage.getItem(key);
    return val ? JSON.parse(val) : defaultValue;
}

export default function useLocalStorage<T>(key: string, defaultValue: T) {
    const [value, setValue] = useState(() => {
        return getLocalStorageValue<T>(key, defaultValue);
    });

    useEffect(() => {
        // save the state if the value changes
        localStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    useEffect(() => {
        // reload the state if the key changes
        setValue(getLocalStorageValue<T>(key, defaultValue));
    }, [key]);

    return [value, setValue] as const;
}