import { useEffect, useRef, useState } from "react";
import readMessageStream from "../utilities/readMessageStream";
import IChatMessage from "../types/interfaces/IChatMessage";
import { MessageRole } from "../types/enums/MessageRole";
import ChatMessage from "./ChatMessage";
import { Box, CircularProgress, Divider } from "@mui/material";
import IModel from "../types/enums/IModel";

export type IncomingMessageStream = ReadableStream<Uint8Array>;

export type IncomingMessageProps = {
  stream?: IncomingMessageStream;
  scrollCallback: () => void;
  model: IModel | null;
};

export default function IncomingMessage({
  stream,
  scrollCallback,
  model,
}: IncomingMessageProps) {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (stream === undefined) return;
    if (stream.locked) return;
    readMessageStream(stream, (token) => {
      setContent((prev) => prev + token);
    })
      .then()
      .catch((reason) => {});
  }, [stream]);

  useEffect(() => {
    scrollCallback();
  }, [content, scrollCallback]);

  const chatMessage: IChatMessage = {
    id: "",
    conversationId: "",
    role: MessageRole.Assistant,
    content: content,
    updated: new Date(),
    model: model,
    hasFeedback: false,
  };
  if (content.trim() !== "") {
    return (
      <Box sx={{ width: "100%" }}>
        <ChatMessage fromUser={false} message={chatMessage} />
      </Box>
    );
  } else {
    return <CircularProgress />;
  }
}
