import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import ConversationListItem from "./ConversationListItem";
import {
  DarkMode,
  EditNote,
  LightMode,
  Logout,
  Settings,
} from "@mui/icons-material";
import { useContext } from "react";
import IConversationInfo from "../types/interfaces/IConversationInfo";
import { useOidc } from "@axa-fr/react-oidc";
import { DarkModeEnabledContext, OidcConfigurationNameContext } from "../index";
import { useNavigate } from "react-router-dom";
import { withTransaction } from "@elastic/apm-rum-react";

export type ConversationListProps = {
  toggleDarkMode: () => void;
  mobile: boolean;
  userName: string | undefined;
  roles: string[] | undefined;
  onHelpOpened: () => void;
  conversationInfoList: IConversationInfo[] | undefined;
  onConversationChanged: (
    id: string,
    name: string,
    updated: Date,
    named: boolean,
  ) => void;
  onConversationDeleted: (id: string) => void;
  onConversationAdded: () => void;
  onConversationClicked: (id: string) => void;
  activeId: string | null | undefined;
  isSending: boolean;
  onGlobalSettingsOpened: () => void;
};

export default withTransaction(
  "ConversationList",
  "component",
)(ConversationList);
function ConversationList({
  toggleDarkMode,
  mobile,
  userName,
  roles,
  conversationInfoList,
  onConversationChanged,
  onConversationDeleted,
  onConversationAdded,
  onConversationClicked,
  activeId,
  isSending,
  onGlobalSettingsOpened,
}: ConversationListProps) {
  const { oidcConfigurationName, clearOidcConfigurationName } = useContext(
    OidcConfigurationNameContext,
  );
  const darkMode = useContext(DarkModeEnabledContext);
  const oidc = useOidc(oidcConfigurationName);
  const navigate = useNavigate();
  const handleLogout = () => {
    oidc.logout(null, { "no_reload:oidc": "true" }).then(() => {
      clearOidcConfigurationName();
      sessionStorage.clear();
      navigate("/login");
    });
  };
  return (
    <>
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {mobile ? (
            <>
              <ListItem>
                <Box
                  display="flex"
                  sx={{ padding: "0.25em" }}
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box>
                    <Tooltip title={"Global Settings"}>
                      <IconButton onClick={onGlobalSettingsOpened}>
                        <Settings />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Toggle Dark Mode"}>
                      <IconButton onClick={toggleDarkMode}>
                        {darkMode ? <LightMode /> : <DarkMode />}
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Tooltip
                    title={"Available Roles: " + roles ?? [""].join(", ")}
                  >
                    <Typography>
                      <b>{userName}</b>
                    </Typography>
                  </Tooltip>
                  <Box component="div">
                    <Tooltip title={"Log out of " + userName + "'s account"}>
                      <IconButton onClick={handleLogout}>
                        <Logout />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </ListItem>
              <Divider />
            </>
          ) : (
            <></>
          )}
          <ListItemButton
            onClick={onConversationAdded}
            disabled={isSending}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <EditNote />
            &nbsp;
            <Typography>New Conversation</Typography>
          </ListItemButton>
          <Divider />
          {conversationInfoList?.map((c) => (
            <ConversationListItem
              conversation={c}
              onConversationInfoChanged={onConversationChanged}
              onConversationDeleted={onConversationDeleted}
              onConversationClicked={onConversationClicked}
              active={c.id === activeId}
              enabled={!isSending}
              key={c.id}
            />
          ))}
        </List>
      </Box>
    </>
  );
}
