import { IconButton, Menu, MenuItem, Stack, TextField } from "@mui/material";
import {
  Cancel,
  Download,
  MenuSharp,
  Send,
  Settings,
  Upload,
} from "@mui/icons-material";
import IChatMessage from "../types/interfaces/IChatMessage";
import React, { ChangeEvent, Fragment, useState } from "react";
import { MessageRole } from "../types/enums/MessageRole";
import { KeyboardEvent, DragEvent } from "react";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import FileAttachment from "./FileAttachment";
import identifyAndParseUploadedFile from "../utilities/identifyAndParseUploadedFile";
import { v4 } from "uuid";

export type ComposeAreaProps = {
  onSend: (message: IChatMessage) => void;
  enabled: boolean;
  isSending: boolean;
  onConversationSettingsOpen: () => void;
  onExportTranscript: () => void;
  onError: (notificationText: string) => void;
  conversationId: string;
  cancelStream: () => void;
};

export default function ComposeArea({
  onSend,
  enabled,
  isSending,
  onConversationSettingsOpen,
  onExportTranscript,
  onError,
  conversationId,
  cancelStream,
}: ComposeAreaProps) {
  const [text, setText] = useState("");
  const [attachedFiles, setAttachedFiles] = useState<
    { name: string; content: string }[]
  >([]);
  const [isOver, setIsOver] = useState(false);

  const handleMessageSubmit = () => {
    if (text.trim() === "") {
      onError("You cannot send a blank message.");
      return;
    }
    const newId = v4();
    const attachedFilesContent = attachedFiles
      .map(
        (file) =>
          `\n===== ATTACHED FILE BELOW======\n${file.name}\n${file.content}`,
      )
      .join("\n\n");

    onSend({
      id: newId,
      role: MessageRole.User,
      content: text + attachedFilesContent,
      conversationId: conversationId,
      updated: new Date(),
      model: null,
      hasFeedback: false,
    });

    setText("");
    setAttachedFiles([]);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey && enabled) {
      e.preventDefault();
      handleMessageSubmit();
    }
  };

  // @ts-ignore
  const closePopup = (ps: PopupState) => {
    ps.close();
  };

  const handleFileUpload = (files: FileList | null) => {
    if (files !== null) {
      Array.from(files).forEach(async (file) => {
        const fileText = await identifyAndParseUploadedFile(file);
        if (fileText === undefined) {
          onError(
            "Sorry, there is an issue with this file or the file type isn't currently supported.",
          );
        } else {
          setAttachedFiles((currentFiles) => [
            ...currentFiles,
            { name: file.name, content: fileText },
          ]);
        }
      });
    }
  };

  const handleDeleteFile = (fileName: string) => {
    setAttachedFiles((currentFiles) =>
      currentFiles.filter((file) => file.name !== fileName),
    );
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = () => {
    setIsOver(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    setIsOver(false);
    handleFileUpload(e.dataTransfer.files);
  };

  return (
    <Stack
      direction="row"
      useFlexGap={true}
      gap="0.5em"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "sticky",
        padding: "0.5em",
        width: "100%",
        marginTop: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        maxHeight: "100%",
        bottom: 0,
      }}
    >
      <PopupState
        parentPopupState={null}
        variant="popover"
        popupId="message-settings-popover"
        disableAutoFocus={false}
      >
        {(popupState) => (
          <Fragment>
            <IconButton
              sx={{ height: "100%", alignSelf: "center" }}
              size="large"
              {...bindTrigger(popupState)}
            >
              <MenuSharp />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem component="label">
                <Upload />
                &nbsp; Attach files
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={(e) => {
                    handleFileUpload(e.target.files);
                    closePopup(popupState);
                  }}
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onExportTranscript();
                  closePopup(popupState);
                }}
              >
                <Download />
                &nbsp; Download conversation transcript
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onConversationSettingsOpen();
                  closePopup(popupState);
                }}
              >
                <Settings />
                &nbsp; Change conversation settings...
              </MenuItem>
            </Menu>
          </Fragment>
        )}
      </PopupState>

      <TextField
        multiline={true}
        value={text}
        onKeyDown={handleKeyDown}
        fullWidth={true}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setText(event.target.value);
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        maxRows={5}
        placeholder="Write a message"
        sx={{
          maxWidth: "960px",
          ...(isOver && {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderStyle: "dashed",
                borderWidth: "2.5px",
              },
            },
          }),
        }}
      />
      {attachedFiles.map((file, index) => (
        <FileAttachment
          key={index}
          onDeleteFile={() => handleDeleteFile(file.name)}
          fileName={file.name}
        />
      ))}
      {isSending ? (
        <IconButton
          content="cancel"
          size="large"
          onClick={cancelStream}
          sx={{ height: "100%", alignSelf: "center" }}
        >
          <Cancel />
        </IconButton>
      ) : (
        <IconButton
          content="send"
          disabled={isSending || !enabled}
          size="large"
          onClick={handleMessageSubmit}
          sx={{ height: "100%", alignSelf: "center" }}
        >
          <Send />
        </IconButton>
      )}
    </Stack>
  );
}
