import IGatewayAPIRequest from "../types/interfaces/IGatewayAPIRequest";

export default function convertGatewayRequest(request: IGatewayAPIRequest) {
  return {
    messages: request.messages,
    model: request.model,
    rag: request.rag,
    stream: request.stream,
    auth_provider: request.authProvider,
  };
}
